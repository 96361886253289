import "./src/styles/global.css"
import browserHydrateFunction from './src/utils/browser-hydrate-function';

// or:
// require('./src/styles/global.css')

export const onRenderBody = ({ setHtmlAttributes }) => {
    setHtmlAttributes({ lang: "es" });
};

export const replaceHydrateFunction = browserHydrateFunction;
